// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Update the import statement
import "./App.css";
import "./styles.css";
import ContactForm from "./ContactForm"; // Import the ContactForm component
// import Footer from "./components/Footer"
import Central from "./components/Central";
import { Helmet } from "react-helmet";
import CareerForm from "./CareerForm";
import PressForom from "./PressForom";

function App() {
  return (
    <Router>
      <Helmet>
        <title>NDAX</title>
        <meta
          name="decription"
          content="Unlock digital assets effortlessly with National Digital Asset Exchange. Our API-first technology and turnkey regulatory infrastructure simplify the launch of compliant digital asset products. Experience frictionless CBDC and digital asset trading, streamline transactions, elevate your banking offering, and tap into endless investment possibilities. Seamlessly navigate the digital asset landscape and unlock a world of opportunities for growth. Contact us now!"
        />
        <meta
          name="keywords"
          content="CBDC infrastructure, digital assets, API-first technology, compliant digital asset products, CBDC trading, frictionless transactions, banking offering, investment possibilities, digital asset landscape, growth opportunities"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Central />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/career" element={<CareerForm />} />
        <Route path="/press" element={<PressForom />} />
      </Routes>
    </Router>
  );
}

export default App;
