import React from "react";
import Footer from "./components/Footer";

const PressForom = () => {
  return (
    <div className="centralized">
      <div className="content">
        <a href="/">
          <img
            className="Ndax-logo"
            src="https://i.postimg.cc/k5jY7gCZ/ndax-logo.png"
            width={"200vw"}
            alt="Logo"
          />
        </a>
        <div>
          <h1>Press </h1>
        </div>
        <div>
          <h4> Coming Soon!</h4>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PressForom;
