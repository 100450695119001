const imagePaths = [
  "https://i.postimg.cc/9Dv6H0mw/1-icon.png",
  "https://i.postimg.cc/VSn3fwvG/2-icon.png",
  "https://i.postimg.cc/WFVxtyJ2/3-icon.png",
  "https://i.postimg.cc/ygTtRTF0/4-icon.png",
  "https://i.postimg.cc/2L8J7BwM/5-icon.png",
  "https://i.postimg.cc/MXWhShS5/6-icon.png",
];
const title = [
  "Trade",
  "Payments",
  "Banks",
  "Investment",
  "Brands",
  "On/Off ramps",
];
const titleData = [
  "Experience frictionless CBDC and digital asset trading on our cutting-edge exchange platform, seize lucrative opportunities with ease.",

  "Streamline your transactions and embrace the future of finance with the effortless digital asset and CBDC payment solutions.",

  "Elevate your banking offering with our transformative solutions, empowering you to offer innovative financial services to your customers.",

  "Tap into endless investment possibilities with progressive CBDC, empowering you to seize the potential of the digital asset revolution.",

  "Expand your brand's horizons and tap into the future of commerce, unlocking a world of opportunities for growth and customer engagement.",

  "Effortlessly navigate the digital asset landscape with our seamless on/off ramps, ensuring smooth transitions and lowest fees.",
];
export { imagePaths, title ,titleData};
