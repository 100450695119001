import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer>
      {/* <Link to="/">Conditions</Link> */}
      <Link to="/">Home</Link>
      <Link to="/press" className="Press">
        Press
      </Link>
      <Link to="https://docs.ndax.in" target="_blank">
        API
      </Link>
      <Link to="/career" className="Career">
        Career
      </Link>
      <Link to="/contact">Contact Us</Link>
      <p className="random">
        Copyright &#169; 2023, National Digital Asset Exchange
      </p>
      <p className="random">Trimtab Innovation Pvt. Ltd.</p>
    </footer>
  );
}

export default Footer;
