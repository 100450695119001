import React, { useEffect, useRef, useState } from "react";
import "./styles2.css";
import Footer from "./components/Footer";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import AppConfig from "./App.config";
import { Helmet } from "react-helmet";
import axios from "axios";
const ContactForm = () => {
  const [message, setMessage] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const MAX_WORD_LIMIT = 500;

  const captcha = useRef();

  const handleSubmit = (event) => {
    const formData = new FormData(event.target);
    const fullName = formData.get("name");
    const country = formData.get("country");
    const companyName = formData.get("company");
    const designation = formData.get("designation");
    const mobileNumber = formData.get("phone");
    const email = formData.get("email");
    const userMessage = formData.get("Massage");

    event.preventDefault();
    const username = "AKIAVG3KVGIQ5K5C54EV";
    const password = "BGI30r7ViaHz5pMhtMjkqw/GDeAD4S3McLoMJltIaaqF";
    const server_addr = "email-smtp.eu-north-1.amazonaws.com";
    const server_port = "587";
    const destination_email = "gewgawrav@gmail.com";
    const sender_email = "test@catax.me";
    const subject = "NDAX Submission Form";
    const body = `
    NDAX - Contact Form Submission
    Email: ${email}
    Full Name: ${fullName}
    Mobile Number: ${mobileNumber}
    Country: ${country}
    Company: ${companyName}
    Designation: ${designation}
    Message:
    ${userMessage}
`;
    const data = {
      username,
      password,
      server_addr,
      server_port,
      destination_email,
      sender_email,
      subject,
      body,
    };
    // Handle form submission logic here
    axios
      .post("https://emailsender.catax.me/sendEmail", data)
      .then((Response) => {
        console.log(Response);
        event.target.reset(); // Fix typo here
      })
      .catch((error) => {
        console.log("Error response data:", error.response.data);
        console.log("Error response status:", error.response.status);
        console.log("Error message:", error.message);
        alert("Failed to send email. Please try again later.");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  }, []);

  const handleMessageChange = (event) => {
    const input = event.target.value;
    const words = input.split(" ");
    if (words.length <= MAX_WORD_LIMIT) {
      setMessage(input);
      return;
    } else {
      setWordCount(words.length);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="decription"
          content="Unlock digital assets effortlessly with National Digital Asset Exchange."
        />
        <meta
          name="keywords"
          content="CBDC infrastructure, digital assets, API-first technology, compliant digital asset products, CBDC trading, frictionless transactions, banking offering, investment possibilities"
        />
        <meta
          property="og:image"
          content="https://i.postimg.cc/50TtcYmp/download.jpg"
        ></meta>
      </Helmet>
      <div className="bodyclassContact">
        <div className="container">
          <a href="/">
            <img src="https://i.postimg.cc/k5jY7gCZ/ndax-logo.png" alt="Logo" />
          </a>
          <h2>Get in Touch</h2>
          <form id="contactForm" onSubmit={handleSubmit}>
            <div className="form-div">
              <input
                placeholder="Full Name"
                type="text"
                id="name"
                name="name"
                style={{ fontFamily: "Open Sans" }}
                required
              />
              <input
                placeholder="Country"
                type="text"
                style={{ fontFamily: "Open Sans" }}
                required
              />
              <input
                placeholder="Company"
                type="text"
                id="company"
                name="company"
                style={{ fontFamily: "Open Sans" }}
                required
              />
              <input
                placeholder="Designation"
                id="Designation"
                name="Designation"
                type="text"
                style={{ fontFamily: "Open Sans" }}
                required
              />

              <input
                placeholder="Mobile Number"
                type="tel"
                style={{ fontFamily: "Open Sans" }}
                id="phone"
                name="phone"
                required
              />
              <input
                placeholder="Email"
                type="email"
                style={{ fontFamily: "Open Sans" }}
                id="email"
                name="email"
                required
              />
              <textarea
                placeholder="Message"
                id="message"
                name="Massage"
                required
                value={message}
                onChange={handleMessageChange}
              ></textarea>
              {wordCount > MAX_WORD_LIMIT && (
                <p style={{ color: "red" }}>500 Word(s) limit exceeded.</p>
              )}
              <HCaptcha ref={captcha} sitekey={AppConfig.hCaptchaSiteToken} />
            </div>
            <div style={{ alignSelf: "center", margin: "5px" }}>
              <button type="submit">SEND</button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactForm;
