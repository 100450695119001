import React from "react";
import "../styles.css";
function Card(props) {
  return (
    <div className="card-css">
      <img
        src={props.src}
        alt="card"
      />
      <h3>{props.title}</h3>
      <p>
        {props.description}
      </p>
    </div>
  );
}

export default Card;
