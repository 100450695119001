// MainApp.js
import React from 'react';
import './styles.css';
import App from './App';
// import ContactForm from './ContactForm';

function MainApp() {
  return (
      <App />
  );
}

export default MainApp;
