import React from "react";
// import dummy from "../dummy.jpeg";
// import dummy2 from "../img_avatar.png";
import { imagePaths, title, titleData } from "./data";
import Footer from "./Footer";
import Card from "./Card";
function Central() {
  return (
    <div className="centralized">
      <div className="content">
        <a href="/">
          <img
            className="Ndax-logo"
            src="https://i.postimg.cc/k5jY7gCZ/ndax-logo.png"
            width={"200vw"}
            alt="Logo"
          />
        </a>
        <div>
          <h1>Pioneering the Infrastructure for CBDC's Future. </h1>
        </div>
        <div>
          <h4>
            {" "}
            Unlock digital assets effortlessly with CBDC! Our API-first
            technology and turnkey regulatory infrastructure simplify the launch
            of compliant digital asset products.{" "}
          </h4>
        </div>
      </div>
      <div className="item">
        {imagePaths.map((imagePath, index) => (
          <Card
            key={index}
            src={imagePath}
            title={title[index]}
            description={titleData[index]}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Central;
